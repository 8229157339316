import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AbnStatusEnum,
  Configuration,
  ErrorResponse,
  GstStatusEnum,
  LookupsApi,
  MembershipStatusEnum,
  PaginatedWorkspacesDto,
  UpdateWorkspaceBusinessDetailsDto,
  UpdateWorkspaceContactDetailsDto,
  UpdateWorkspaceDetailsDto,
  UpdateWorkspaceDto,
  Workspace,
  WorkspaceStatusEnum,
  WorkspacesApi,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance, {
  invalidateCacheRequestConfig,
} from '../../utils/axiosInstance';
import { WorkspaceSliceState } from './workspaceSliceState';

// Function for creating an instance of the UsersApi class
const GetLookupsApi = () => {
  return new LookupsApi(
    new Configuration(),
    configuration.WORKSPACES_API_BASE,
    axiosInstance,
  );
};

const GetWorkspacesApi = () => {
  return new WorkspacesApi(
    new Configuration(),
    configuration.WORKSPACES_API_BASE,
    axiosInstance,
  );
};

const findAll = createAsyncThunk('workspace/findAll', async (_, thunkApi) => {
  try {
    const result = await GetWorkspacesApi().workspaceControllerFindAll();

    return result.data;
  } catch (error) {
    return thunkApi.rejectWithValue(
      (error as AxiosError).response?.data as ErrorResponse,
    );
  }
});

const findAllPaginated = createAsyncThunk(
  'workspace/findAllPaginated',
  async (
    {
      MembershipStatusEnum,
      abnStatus,
      gstStatus,
      name,
      page = 1,
      pageSize = 10,
      sortBy = 'workspaceName:asc',
      workspaceStatus,
    }: {
      MembershipStatusEnum?: MembershipStatusEnum[];
      abnStatus?: AbnStatusEnum[];
      gstStatus?: GstStatusEnum[];
      name?: string;
      page?: number;
      pageSize?: number;
      sortBy?: string;
      workspaceStatus?: WorkspaceStatusEnum[];
    },
    thunkApi,
  ) => {
    try {
      const result = await GetWorkspacesApi().workspaceControllerFindAllV2(
        abnStatus,
        gstStatus,
        MembershipStatusEnum,
        name,
        workspaceStatus,
        page,
        pageSize,
        sortBy,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findOne = createAsyncThunk(
  'workspace/findOne',
  async (id: string, thunkApi) => {
    try {
      const result = await GetWorkspacesApi().workspaceControllerFindOne(id);

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const updateContactDetails = createAsyncThunk(
  'workspace/updateContactDetails',
  async (
    {
      id,
      updateWorkspaceContactDetailsDto,
    }: {
      id: string;
      updateWorkspaceContactDetailsDto: UpdateWorkspaceContactDetailsDto;
    },
    thunkApi,
  ) => {
    try {
      const result =
        await GetWorkspacesApi().workspaceControllerUpdateContactDetails(
          id,
          updateWorkspaceContactDetailsDto,
          invalidateCacheRequestConfig(`workspace/${id}`),
        );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const updateBusinessDetails = createAsyncThunk(
  'workspace/updateBusinessDetails',
  async (
    {
      id,
      updateWorkspaceBusinessDetailsDto,
    }: {
      id: string;
      updateWorkspaceBusinessDetailsDto: UpdateWorkspaceBusinessDetailsDto;
    },
    thunkApi,
  ) => {
    try {
      const result =
        await GetWorkspacesApi().workspaceControllerUpdateBusinessDetails(
          id,
          updateWorkspaceBusinessDetailsDto,
          invalidateCacheRequestConfig(`workspace/${id}`),
        );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const lookupBusinessNumber = createAsyncThunk(
  'workspace/lookupBusinessNumber',
  async (businessNumber: string, thunkApi) => {
    try {
      // TODO: if payload.country == AU
      const result =
        await GetLookupsApi().businessNumberLookupControllerLookupAbn(
          businessNumber,
        );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const updateDetails = createAsyncThunk(
  'workspace/updateDetails',
  async (
    {
      id,
      updateWorkspaceDetailsDto,
    }: {
      id: string;
      updateWorkspaceDetailsDto: UpdateWorkspaceDetailsDto;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetWorkspacesApi().workspaceControllerUpdateDetails(
        id,
        updateWorkspaceDetailsDto,
        invalidateCacheRequestConfig('workspace'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const update = createAsyncThunk(
  'workspace/update',
  async (
    {
      id,
      updateWorkspaceDto,
    }: {
      id: string;
      updateWorkspaceDto: UpdateWorkspaceDto;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetWorkspacesApi().workspaceControllerUpdate(
        id,
        updateWorkspaceDto,
        invalidateCacheRequestConfig('workspace'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

export const initialWorkSpaceState: WorkspaceSliceState = {
  isBusinessDetailsUpdating: ApiLoadingStateEnum.idle,
  isContactDetailsUpdating: ApiLoadingStateEnum.idle,
  isFindAllWorkspaceLoading: ApiLoadingStateEnum.idle,
  isLoading: ApiLoadingStateEnum.idle,
  isLookupLoading: ApiLoadingStateEnum.idle,
  isUpdating: ApiLoadingStateEnum.idle,
  isWorkspaceLoading: ApiLoadingStateEnum.idle,
  workspace: undefined,
  workspaces: [],
};

const workspaceSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // FindAll
      .addCase(findAll.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;
        state.workspaces = action.payload as Workspace[];
      })
      .addCase(findAll.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // FindAllPaginated
      .addCase(findAllPaginated.fulfilled, (state, action) => {
        state.isFindAllWorkspaceLoading = ApiLoadingStateEnum.succeeded;

        const paginatedResponse = action.payload as PaginatedWorkspacesDto;

        state.workspaces = paginatedResponse.items;
        state.paginationMeta = paginatedResponse.meta;
      })
      .addCase(findAllPaginated.pending, (state) => {
        state.isFindAllWorkspaceLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAllPaginated.rejected, (state, action) => {
        state.isFindAllWorkspaceLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // Update workspace details
      .addCase(updateDetails.fulfilled, (state, action) => {
        state.isUpdating = ApiLoadingStateEnum.succeeded;
        state.workspace = action.payload;
      })
      .addCase(updateDetails.pending, (state) => {
        state.isUpdating = ApiLoadingStateEnum.loading;
      })
      .addCase(updateDetails.rejected, (state, action) => {
        state.isUpdating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // Update
      .addCase(update.fulfilled, (state, action) => {
        state.isUpdating = ApiLoadingStateEnum.succeeded;
        state.workspace = action.payload;
      })
      .addCase(update.pending, (state) => {
        state.isUpdating = ApiLoadingStateEnum.loading;
      })
      .addCase(update.rejected, (state, action) => {
        state.isUpdating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // UpdateContactDetails
      .addCase(updateContactDetails.fulfilled, (state, action) => {
        state.workspace = {
          ...state.workspace,
          addresses: action.payload.addresses,
          emailAddress: action.payload.emailAddress,
          phone: action.payload.phone,
          updated: action.payload.updated,
          website: action.payload.website,
        } as Workspace;
        state.isContactDetailsUpdating = ApiLoadingStateEnum.succeeded;
      })
      .addCase(updateContactDetails.pending, (state) => {
        state.isContactDetailsUpdating = ApiLoadingStateEnum.loading;
      })
      .addCase(updateContactDetails.rejected, (state, action) => {
        state.isContactDetailsUpdating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // UpdateBusinessDetails
      .addCase(updateBusinessDetails.fulfilled, (state, action) => {
        state.workspace = {
          ...state.workspace,
          businessNumber: action.payload.businessNumber,
          businessNumberCountry: action.payload.businessNumberCountry,
          businessNumberVerified: action.payload.businessNumberVerified,
          entityName: action.payload.entityName,
          updated: action.payload.updated,
          workspaceName: action.payload.workspaceName,
        } as Workspace;
        state.isBusinessDetailsUpdating = ApiLoadingStateEnum.succeeded;
      })
      .addCase(updateBusinessDetails.pending, (state) => {
        state.isBusinessDetailsUpdating = ApiLoadingStateEnum.loading;
      })
      .addCase(updateBusinessDetails.rejected, (state, action) => {
        state.isBusinessDetailsUpdating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // LookupBusinessNumber
      .addCase(lookupBusinessNumber.fulfilled, (state, action) => {
        state.isLookupLoading = ApiLoadingStateEnum.succeeded;
        state.businessNumberLookup = action.payload;
      })
      .addCase(lookupBusinessNumber.pending, (state) => {
        state.isLookupLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(lookupBusinessNumber.rejected, (state, action) => {
        state.isLookupLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // FindOne
      .addCase(findOne.fulfilled, (state, action) => {
        state.isWorkspaceLoading = ApiLoadingStateEnum.succeeded;
        state.workspace = action.payload;
      })
      .addCase(findOne.pending, (state) => {
        state.isWorkspaceLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findOne.rejected, (state, action) => {
        state.isWorkspaceLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialWorkSpaceState,
  name: 'workspace',
  reducers: {
    reset: () => {
      return {
        ...initialWorkSpaceState,
      };
    },
    resetErrorResponse: (state) => {
      return {
        ...state,
        errorResponse: initialWorkSpaceState.errorResponse,
      };
    },
    resetLoadingState: (state) => {
      return {
        ...state,
        // Add more loading state here
        isUpdating: ApiLoadingStateEnum.idle,
      };
    },
    resetSingleWorkspace: (state) => {
      return {
        ...state,
        workspace: initialWorkSpaceState.workspace,
      };
    },
  },
});

const workspaceThunk = {
  findAll,
  findAllPaginated,
  findOne,
  lookupBusinessNumber,
  update,
  updateBusinessDetails,
  updateContactDetails,
  updateDetails,
};

const workspaceSelectors = {
  businessNumberLookup: (state: RootState) =>
    state.workspaces.businessNumberLookup,
  errorResponse: (state: RootState) => state.workspaces.errorResponse,
  isBusinessDetailsUpdating: (state: RootState) =>
    state.workspaces.isBusinessDetailsUpdating,
  isContactDetailsUpdating: (state: RootState) =>
    state.workspaces.isContactDetailsUpdating,
  isFindAllWorkspaceLoading: (state: RootState) =>
    state.workspaces.isFindAllWorkspaceLoading,
  isLoading: (state: RootState) => state.workspaces.isLoading,
  isLookupLoading: (state: RootState) => state.workspaces.isLookupLoading,
  isUpdating: (state: RootState) => state.workspaces.isUpdating,
  isWorkspaceLoading: (state: RootState) => state.workspaces.isWorkspaceLoading,
  paginationMeta: (state: RootState) => state.workspaces.paginationMeta,
  workspace: (state: RootState) => state.workspaces.workspace,
  workspaces: (state: RootState) => state.workspaces.workspaces,
};

export const workspaceService = {
  ...workspaceThunk,
  actions: workspaceSlice.actions,
  selectors: workspaceSelectors,
};

export default workspaceSlice.reducer;
